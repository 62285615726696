import React, { createContext, useCallback, useEffect, useState } from 'react';

const SM_MIN = 640;
const MD_MIN = 768;
const LG_MIN = 1024;
const XL_MIN = 1280;
const XXL_MIN = 1536;

export const isMobile = (ws) => ws.width < SM_MIN;
export const isSmallOrLess = (ws) => ws.width < MD_MIN;
export const isMediumOrLess = (ws) => ws.width < LG_MIN;
export const isLargeOrLess = (ws) => ws.width < XL_MIN;
export const isXLargeOrLess = (ws) => ws.width < XXL_MIN;
export const is2xlOrLess = (ws) => ws.width >= XXL_MIN;

export const isSmallOrMore = (ws) => ws.width >= SM_MIN;
export const isMediumOrMore = (ws) => ws.width >= MD_MIN;
export const isLargeOrMore = (ws) => ws.width >= LG_MIN;
export const isXLargeOrMore = (ws) => ws.width >= XL_MIN;
export const is2xlOrMore = (ws) => ws.width >= XXL_MIN;

const getSize = (ws) => {
  if (isMobile(ws)) {
    return 'xs';
  } else if (isSmallOrLess(ws)) {
    return 'sm';
  } else if (isMediumOrLess(ws)) {
    return 'md';
  } else if (isLargeOrLess(ws)) {
    return 'lg';
  } else if (isXLargeOrLess(ws)) {
    return 'xl';
  } else {
    return '2xl';
  }
};

export const WindowSizeContext = createContext({
  width: 0,
  height: 0,
  size: 'xs',
});

const WindowSizeContextProvider = (props) => {
  const getHeight = useCallback(() => {
    return Math.max(
      typeof document !== 'undefined' ? document.documentElement.clientHeight : 0,
      typeof window !== 'undefined' ? window.innerHeight : 0
    );
  }, []);
  const getWidth = useCallback(() => {
    return Math.max(
      typeof document !== 'undefined' ? document.documentElement.clientWidth : 0,
      typeof window !== 'undefined' ? window.innerWidth : 0
    );
  }, []);

  const [size, setSize] = useState({
    width: getHeight(),
    height: getWidth(),
    size: 'xs',
  });

  useEffect(() => {
    const updateSize = () => {
      const width = getWidth();
      setSize({
        width: width,
        height: getHeight(),
        size: getSize({ width }),
      });
    };

    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('orientationchange', updateSize);
    };
  }, [getHeight, getWidth]);

  return <WindowSizeContext.Provider value={{ ...size }}>{props ? props.children : null}</WindowSizeContext.Provider>;
};

export default WindowSizeContextProvider;
