import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from '@apollo/client';
const apiURL = process.env.STRAPI_API_URL;

const httpLink = new HttpLink({
  uri: `${apiURL}/graphql`,
  fetch,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const user = JSON.parse(localStorage.getItem('gatsbyUser'));
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: user ? `Bearer ${user.jwt}` : null,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default client;
